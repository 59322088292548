var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-11",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"background",attrs:{"headers":_vm.headers,"items":_vm.groups,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-spacer'),_c('v-tooltip',{attrs:{"top":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"text-center"},[_c('v-btn',_vm._g({class:_vm.$vuetify.breakpoint.width > 960
                    ? 'ml-0 mr-3 xs-small'
                    : 'ml-3 xs-small',attrs:{"slot":"activator","small":"","elevation":"0","fab":"","dark":"","color":"primary"},on:{"click":function($event){return _vm.openAddUsersDialog(item)}},slot:"activator"},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-user-plus")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Add user to location group")])]),_c('v-tooltip',{attrs:{"top":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('div',{staticClass:"text-center"},[_c('v-btn',_vm._g({class:_vm.$vuetify.breakpoint.width > 960
                    ? 'ml-0 mr-3 xs-small'
                    : 'ml-3 xs-small',attrs:{"slot":"activator","small":"","elevation":"0","fab":"","dark":"","color":"primary"},on:{"click":function($event){return _vm.editLocationGroup(item)}},slot:"activator"},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Edit Location Group")])]),_c('v-tooltip',{attrs:{"top":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('div',{staticClass:"text-center"},[_c('v-btn',_vm._g({class:_vm.$vuetify.breakpoint.width > 960
                    ? 'ml-0 mr-3 xs-small'
                    : 'ml-3 xs-small',attrs:{"slot":"activator","small":"","elevation":"0","fab":"","dark":"","color":"primary"},on:{"click":function($event){return _vm.modifyLocations(item)}},slot:"activator"},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("place")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("View & Modifiy "+_vm._s(_vm._is50c?'Organizations' : 'Locations'))])])],1)]}}])}),_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"500px","transition":"dialog-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v("Create Location Group")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Group Name"},model:{value:(_vm.newGroup.name),callback:function ($$v) {_vm.$set(_vm.newGroup, "name", $$v)},expression:"newGroup.name"}}),_c('v-textarea',{attrs:{"label":"Group Description"},model:{value:(_vm.newGroup.description),callback:function ($$v) {_vm.$set(_vm.newGroup, "description", $$v)},expression:"newGroup.description"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dialog = false;
            (_vm.edit = false), (_vm.newGroup.name = ''), (_vm.newGroup.description = '');
            _vm.selectedLocationGroup = {};}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"primary"},on:{"click":_vm.handleConfirm}},[_vm._v("Confirm")])],1)],1)],1),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","fixed":"","dark":"","bottom":"","right":"","color":"primary"},on:{"click":function($event){_vm.dialog = true}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Create group")])]),(_vm.addUsersDialog)?_c('users',{attrs:{"closedOnce":_vm.userModalClosedOnce,"status":_vm.addUsersDialog,"location":_vm.selectedLocationGroup,"loadingPage":true,"group":true},on:{"close-users-dialog":_vm.closeAddUsersDialog}}):_vm._e(),(_vm.locationGroupDialog)?_c('location-group-modal',{attrs:{"status":_vm.locationGroupDialog,"locationGroup":_vm.selectedLocationGroup,"loadingPage":true},on:{"close-users-dialog":function($event){_vm.locationGroupDialog = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }