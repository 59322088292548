<template>
  <v-container fluid class="px-11">
    <v-data-table
      class="background"
      :headers="headers"
      :items="groups"
      hide-default-footer
    >
      <template v-slot:item.actions="{ item }">
        <v-row>
          <v-spacer></v-spacer>
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <div class="text-center">
                <v-btn
                  small
                  elevation="0"
                  @click="openAddUsersDialog(item)"
                  slot="activator"
                  fab
                  dark
                  v-on="on"
                  color="primary"
                  :class="
                    $vuetify.breakpoint.width > 960
                      ? 'ml-0 mr-3 xs-small'
                      : 'ml-3 xs-small'
                  "
                >
                  <v-icon small>fas fa-user-plus</v-icon>
                </v-btn>
              </div>
            </template>
            <span>Add user to location group</span>
          </v-tooltip>
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <div class="text-center">
                <v-btn
                  small
                  elevation="0"
                  @click="editLocationGroup(item)"
                  slot="activator"
                  fab
                  dark
                  v-on="on"
                  color="primary"
                  :class="
                    $vuetify.breakpoint.width > 960
                      ? 'ml-0 mr-3 xs-small'
                      : 'ml-3 xs-small'
                  "
                >
                  <v-icon small>edit</v-icon>
                </v-btn>
              </div>
            </template>
            <span>Edit Location Group</span>
          </v-tooltip>
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <div class="text-center">
                <v-btn
                  small
                  elevation="0"
                  @click="modifyLocations(item)"
                  slot="activator"
                  fab
                  dark
                  v-on="on"
                  color="primary"
                  :class="
                    $vuetify.breakpoint.width > 960
                      ? 'ml-0 mr-3 xs-small'
                      : 'ml-3 xs-small'
                  "
                >
                  <v-icon small>place</v-icon>
                </v-btn>
              </div>
            </template>
            <span>View &amp; Modifiy {{_is50c?'Organizations' : 'Locations'}}</span>
          </v-tooltip>
        </v-row>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>Create Location Group</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newGroup.name"
            label="Group Name"
          ></v-text-field>
          <v-textarea
            v-model="newGroup.description"
            label="Group Description"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="
              dialog = false;
              (edit = false), (newGroup.name = ''), (newGroup.description = '');
              selectedLocationGroup = {};
            "
            text
            class="text-capitalize"
            color="primary"
            >Cancel</v-btn
          >
          <v-btn @click="handleConfirm" class="text-capitalize" color="primary"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-btn
          @click="dialog = true"
          fab
          fixed
          dark
          bottom
          right
          color="primary"
          v-on="on"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <span>Create group</span>
    </v-tooltip>
    <users
      :closedOnce="userModalClosedOnce"
      v-if="addUsersDialog"
      :status="addUsersDialog"
      :location="selectedLocationGroup"
      :loadingPage="true"
      :group="true"
      @close-users-dialog="closeAddUsersDialog"
    ></users>
    <location-group-modal
      v-if="locationGroupDialog"
      :status="locationGroupDialog"
      :locationGroup="selectedLocationGroup"
      :loadingPage="true"
      @close-users-dialog="locationGroupDialog = false"
    ></location-group-modal>
  </v-container>
</template>

<script>
import { LocationsAPI } from '@/clients/locations/locationsapi'

import { mapGetters } from 'vuex'

export default {
  components: {
    Users: () => import('@/views/Locations/users'),
    LocationGroupModal: () => import('./LocationGroupModal')
  },
  data() {
    return {
      addUsersDialog: false,
      locationGroupDialog: false,
      dialog: false,
      search: '',
      newGroup: {
        name: '',
        description: ''
      },
      groups: [],
      headers: [
        {
          text: 'Name',
          value: 'name',
          sortable: true,
          align: ''
        },
        {
          text: 'Description',
          value: 'description',
          sortable: false,
          align: ''
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'end'
        }
      ],
      selectedLocationGroup: {},
      edit: false,
      userModalClosedOnce: false
    }
  },
  async mounted() {
    this.fetchLocationGroups()
    if (this.$route.query.location_group_id) {
      this.handleLocationGroupInUrl(this.$route.query.location_group_id)
    }
  },
  methods: {
    async fetchLocationGroups() {
      let response
      try {
        response = await LocationsAPI.getLocationGroups()
      } catch (error) {
        this.groups = []
      }
      this.groups = response.data.payload
    },
    async createNewLocationGroup() {
      let response
      try {
        response = await LocationsAPI.createNewLocationGroup(this.newGroup)
      } catch (error) {
        return console.log(error)
      }
      this.groups.push(response.data.payload)
      this.dialog = false
    },
    async openAddUsersDialog(locationGroup) {
      this.selectedLocationGroup = locationGroup
      this.addUsersDialog = true
    },
    closeAddUsersDialog() {
      this.addUsersDialog = false
      this.selectedLocationGroup = {}
      this.userModalClosedOnce = true
    },
    async editLocationGroup(locationGroup) {
      this.edit = true
      this.selectedLocationGroup = locationGroup
      this.newGroup.name = locationGroup.name
      this.newGroup.description = locationGroup.description
      this.dialog = true
    },
    async updateLocationGroup() {
      let response
      try {
        response = await LocationsAPI.updateLocationGroupById(this.selectedLocationGroup.id, this.newGroup)
      } catch (error) {
        this.groups = []
        return console.log(error)
      }
      this.dialog = false
      this.fetchLocationGroups()
    },
    handleConfirm() {
      if (this.edit) {
        this.updateLocationGroup()
      } else {
        this.createNewLocationGroup()
      }
    },
    modifyLocations(locationGroup) {
      this.selectedLocationGroup = locationGroup
      this.locationGroupDialog = true
    },
    async handleLocationGroupInUrl(locGroupId) {
      let res
      try {
        res = await LocationsAPI.getLocationGroupById(locGroupId)
      } catch (error) {
        return console.log(error)
      }
      this.selectedLocationGroup = res.data.payload
      this.addUsersDialog = true
    }
  },
  computed: {
    ...mapGetters({
      vg_test: 'applications/locationsAxiosInstance'
    })
  }
}
</script>

<style>
</style>
