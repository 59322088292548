import { authToken } from '../env'
import store from '@/store/index'

export const LocationsAPI = {
  getAllLocations(page, limit) {
    return store.getters['applications/locationsAxiosInstance'](
      {
        method: 'get',
        url: `/locations`,
        params: {
          page: page || 1,
          limit: limit || 10
        },
        headers: {
          'auth-token': authToken()
        }
      }
    )
  },
  create(data) {
    return store.getters['applications/locationsAxiosInstance']({
      method: 'post',
      url: `/locations`,
      headers: {
        'auth-token': authToken()
      },
      data
    })
  },
  updateById(locationId, data) {
    return store.getters['applications/locationsAxiosInstance']({
      method: 'patch',
      url: `/locations/${locationId}`,
      headers: {
        'auth-token': authToken()
      },
      data
    })
  },
  deleteById(locationId) {
    return store.getters['applications/locationsAxiosInstance']({
      method: 'delete',
      url: `/locations/${locationId}`,
      headers: {
        'auth-token': authToken()
      }
    })
  },
  getLocationSettingsCategories(businessUUID) {
    return store.getters['applications/locationsAxiosInstance']({
      method: 'get',
      url: `/location_setting_categories/businesses/${businessUUID}?options=false`,
      headers: {
        'auth-token': authToken()
      }
    })
  },
  toggleLocationSetting(businessUUID, settingName, toggleValue) {
    return store.getters['applications/locationsAxiosInstance']({
      method: 'POST',
      url: `/location_setting_categories/businesses/${businessUUID}/settings/${settingName}`,
      headers: {
        'auth-token': authToken()
      },
      data: {
        is_active: toggleValue
      }
    })
  },
  getLocationGroups() {
    return store.getters['applications/locationsAxiosInstance']({
      method: 'get',
      url: `/groups`,
      params: {
        withLocations: true
      },
      headers: {
        'auth-token': authToken()
      }
    })
  },
  createNewLocationGroup(data) {
    return store.getters['applications/locationsAxiosInstance']({
      method: 'post',
      url: `/groups`,
      data,
      headers: {
        'auth-token': authToken()
      }
    })
  },
  addUserToLocationGroup(userId, data) {
    return store.getters['applications/locationsAxiosInstance']({
      method: 'post',
      url: `/users/${userId}/locations/groups`,
      headers: {
        'auth-token': authToken()
      },
      data
    })
  },
  updateLocationGroupById(locationGroupId, data) {
    return store.getters['applications/locationsAxiosInstance']({
      method: 'patch',
      url: `/groups/${locationGroupId}`,
      headers: {
        'auth-token': authToken()
      },
      data
    })
  },
  getLocationGroupWithLocations(locationGroupId) {
    return store.getters['applications/locationsAxiosInstance']({
      method: 'get',
      url: `/groups/${locationGroupId}?withLocations=true&inactive=true`,
      headers: {
        'auth-token': authToken()
      }
    })
  },
  addLocationToLocationGroup(locationGroupId, locationId) {
    return store.getters['applications/locationsAxiosInstance']({
      method: 'post',
      url: `/groups/${locationGroupId}/locations/${locationId}`,
      headers: {
        'auth-token': authToken()
      }
    })
  },
  removeLocationFromLocationGroup(locationGroupId, locationId) {
    return store.getters['applications/locationsAxiosInstance']({
      method: 'delete',
      url: `/groups/${locationGroupId}/locations/${locationId}`,
      headers: {
        'auth-token': authToken()
      }
    })
  },
  getLocationGroupUsers(locationGroupId, inactive = true) {
    return store.getters['applications/locationsAxiosInstance']({
      method: 'GET',
      url: `/groups/${locationGroupId}/users?inactive=${inactive}`,
      headers: {
        'auth-token': authToken()
      }
    })
  },
  removeUserFromLocationGroup(locationGroupId, userId) {
    return store.getters['applications/locationsAxiosInstance']({
      method: 'DELETE',
      url: `/users/${userId}/locations/groups/${locationGroupId}`,
      headers: {
        'auth-token': authToken()
      }
    })
  },
  getAllLocationsForUser(businessUUID, userId, withGroups = false) {
    return store.getters['applications/locationsAxiosInstance']({
      method: 'GET',
      url: `/integrators/businesses/${businessUUID}/users/${userId}/locations?withGroups=${withGroups}`,
      headers: {
        'auth-token': authToken()
      }
    })
  },
  getLocationById(locId) {
    return store.getters['applications/locationsAxiosInstance']({
      method: 'GET',
      url: `/locations/${locId}?contactDetails=false&address=false&settings=false&images=false&hours=false&groups=false`,
      headers: {
        'auth-token': authToken()
      }
    })
  },
  getLocationGroupById(locGroupId) {
    return store.getters['applications/locationsAxiosInstance']({
      method: 'get',
      url: `/groups/${locGroupId}`,
      params: {
        withLocations: true
      },
      headers: {
        'auth-token': authToken()
      }
    })
  },
  searchLocation(term) {
    return store.getters['applications/locationsAxiosInstance']({
      method: 'get',
      url: `/locations/search?term=${term}`,
      headers: {
        'auth-token': authToken()
      }
    })
  }
}
